/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-03-05 10:42:12
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-14 16:00:17
 */

import config from '@/config'

export default {
  data() {
    return {
      // * 定时函数
      timing: null,
      config,
    }
  },
  mounted() {
    this.timeFn()
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.pageInit()
      }, config.pageReloadTime);
    },
  },
}