/*
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-03-29 14:26:44
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-18 08:51:56
 */
export default {
    projectName: '51公需网数据驾驶舱',
    pageReloadTime: 5 * 60 * 1000
}